import { getUserCountry } from '@whitelabel/helpers/site';
import { AMAZON_COUNTRY_CODES } from './constants';

export const allowedPartner = {
  revolut: 'revolut',
  amazonGroup: 'amazon',
};

export const ZENDESK_CHAT_APP_ID: Partial<Record<string, string>> = {
  [allowedPartner.revolut]: '517074fc-dd89-474e-9f5f-adf6b35d570c',
  [allowedPartner.amazonGroup]: 'c77739ee-fa75-4122-b54f-ba77fbc7f282',
};

export const isAmazonGroup = (partnerSlug: string) => new RegExp(allowedPartner.amazonGroup, 'i').test(partnerSlug);

export const showChatButton = (partnerSlug?: string) =>
  // On PASH page there is no partner id, so we check the url instead, e.g. /help/partners/amazon
  partnerSlug && !isAmazonGroup(partnerSlug) && Object.keys(ZENDESK_CHAT_APP_ID).includes(partnerSlug);

export const checkTabOption = (partnerSlug?: string) =>
  partnerSlug && isAmazonGroup(partnerSlug) && AMAZON_COUNTRY_CODES.includes(getUserCountry());
